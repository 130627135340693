<template lang="html">
  <div class="">
    <img src="@/assets/logonewest.svg" alt="" id="logo">
  </div>
<div class="opis">


<h1>NEO PI-R Test Ličnosti</h1>

<p>Ispred Vas se nalazi NEO PI-R test ličnosti, potrebno je da odgovorite na sve tvrdnje.</p>
<p>U ovom testu ne postoje tačni i netačni odgovori.</p>
<p>Takođe, prilikom odgovaranja izbegavati neutralan odgovor (3), jer nije prirodno da budete neutralni po većini pitanja.</p>
<p>Predviđeno vreme za popunjavanje testa je 1h i 15min. </p>

<h2 class="vreme">Vreme: {{Math.floor(timerCount/3600)}}:{{Math.floor(timerCount/60)%60}}:{{timerCount%60}}</h2>
<div class="progress">
  <div id="prog" class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
</div>

</div>
<div class="row">
  <div class="card mx-auto">
<div class="card-body">

<form @submit.prevent="submitTestLicnostiThis">
    <div class="form-group">
      <h3>1. Nisam osoba koja se puno brine.</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans1a" id="ans1a" v-model="ans1" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans1b" id="ans1b" v-model="ans1" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans1c" id="ans1c" v-model="ans1" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans1d" id="ans1d" v-model="ans1" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans1e" id="ans1e" v-model="ans1" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>


    <div class="form-group">
      <h3>2. Zaista mi se sviđa većina ljudi koje srećem.</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans2a" id="ans2a" v-model="ans2" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans2b" id="ans2b" v-model="ans2" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans2c" id="ans2c" v-model="ans2" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans2d" id="ans2d" v-model="ans2" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans2e" id="ans2e" v-model="ans2" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>



    <div class="form-group">
      <h3>3. Imam veoma živu maštu.</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans3a" id="ans3a" v-model="ans3" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans3b" id="ans3b" v-model="ans3" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans3c" id="ans3c" v-model="ans3" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans3d" id="ans3d" v-model="ans3" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans3e" id="ans3e" v-model="ans3" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>



    <div class="form-group">
      <h3>4. Umem da budem ciničan/a i skeptičan/a prema namerama drugih ljudi.</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans4a" id="ans4a" v-model="ans4" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans4b" id="ans4b" v-model="ans4" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans4c" id="ans4c" v-model="ans4" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans4d" id="ans4d" v-model="ans4" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans4e" id="ans4e" v-model="ans4" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>



    <div class="form-group">
      <h3>5. Poznat/a sam po svojoj razboritosti i zdravom razumu.</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans5a" id="ans5a" v-model="ans5" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans5b" id="ans5b" v-model="ans5" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans5c" id="ans5c" v-model="ans5" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans5d" id="ans5d" v-model="ans5" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans5e" id="ans5e" v-model="ans5" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>



    <div class="form-group">
      <h3>6. Često me ljuti način na koji ljudi sa mnom postupaju.</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans6a" id="ans6a" v-model="ans6" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans6b" id="ans6b" v-model="ans6" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans6c" id="ans6c" v-model="ans6" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans6d" id="ans6d" v-model="ans6" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans6e" id="ans6e" v-model="ans6" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>



    <div class="form-group">
      <h3>7. Izbegavam da budem u mnoštvu ljudi.</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans7a" id="ans7a" v-model="ans7" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans7b" id="ans7b" v-model="ans7" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans7c" id="ans7c" v-model="ans7" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans7d" id="ans7d" v-model="ans7" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans7e" id="ans7e" v-model="ans7" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>



    <div class="form-group">
      <h3>8. Nisu mi posebno važna estetska i umetnička pitanja.</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans8a" id="ans8a" v-model="ans8" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans8b" id="ans8b" v-model="ans8" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans8c" id="ans8c" v-model="ans8" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans8d" id="ans8d" v-model="ans8" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans8e" id="ans8e" v-model="ans8" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>


    <div class="form-group">
      <h3>9. Nisam prepreden/a ili podmukao/la.</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans9a" id="ans9a" v-model="ans9" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans9b" id="ans9b" v-model="ans9" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans9c" id="ans9c" v-model="ans9" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans9d" id="ans9d" v-model="ans9" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans9e" id="ans9e" v-model="ans9" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>



    <div class="form-group">
      <h3>10. Radije ću ostaviti neke mogućnosti otvorene nego da sve unapred isplaniram.</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans10a" id="ans10a" v-model="ans10" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans10b" id="ans10b" v-model="ans10" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans10c" id="ans10c" v-model="ans10" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans10d" id="ans10d" v-model="ans10" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans10e" id="ans10e" v-model="ans10" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>

    <div class="form-group">
      <h3>11. Retko se osećam usamljeno ili potišteno.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans11a" id="ans11a" v-model="ans11" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans11b" id="ans11b" v-model="ans11" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans11c" id="ans11c" v-model="ans11" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans11d" id="ans11d" v-model="ans11" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans11e" id="ans11e" v-model="ans11" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>

    <div class="form-group">
      <h3>12. Ja sam dominantan/a, čvrst/a i prodoran/a.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans12a" id="ans12a" v-model="ans12" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans12b" id="ans12b" v-model="ans12" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans12c" id="ans12c" v-model="ans12" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans12d" id="ans12d" v-model="ans12" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans12e" id="ans12e" v-model="ans12" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>13. Za mene bi život bez snažnih emocija bio nezanimljiv.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans13a" id="ans13a" v-model="ans13" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans13b" id="ans13b" v-model="ans13" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans13c" id="ans13c" v-model="ans13" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans13d" id="ans13d" v-model="ans13" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans13e" id="ans13e" v-model="ans13" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>14. Neki ljudi misle da sam sebičan/a i egoističan/a.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans14a" id="ans14a" v-model="ans14" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans14b" id="ans14b" v-model="ans14" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans14c" id="ans14c" v-model="ans14" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans14d" id="ans14d" v-model="ans14" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans14e" id="ans14e" v-model="ans14" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>15. Trudim se da savesno obavim zadatke koji su mi povereni.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans15a" id="ans15a" v-model="ans15" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans15b" id="ans15b" v-model="ans15" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans15c" id="ans15c" v-model="ans15" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans15d" id="ans15d" v-model="ans15" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans15e" id="ans15e" v-model="ans15" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>16. U odnosu sa drugim ljudima uvek strepim da ću da napravim neku glupost.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans16a" id="ans16a" v-model="ans16" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans16b" id="ans16b" v-model="ans16" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans16c" id="ans16c" v-model="ans16" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans16d" id="ans16d" v-model="ans16" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans16e" id="ans16e" v-model="ans16" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>17. Moj stil rada je opušten i bez žurbe.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans17a" id="ans17a" v-model="ans17" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans17b" id="ans17b" v-model="ans17" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans17c" id="ans17c" v-model="ans17" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans17d" id="ans17d" v-model="ans17" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans17e" id="ans17e" v-model="ans17" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>18. Dosta se držim svog načina života.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans18a" id="ans18a" v-model="ans18" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans18b" id="ans18b" v-model="ans18" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans18c" id="ans18c" v-model="ans18" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans18d" id="ans18d" v-model="ans18" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans18e" id="ans18e" v-model="ans18" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>19. Radije bih sarađivao/la sa drugima nego se takmičio/la.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans19a" id="ans19a" v-model="ans19" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans19b" id="ans19b" v-model="ans19" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans19c" id="ans19c" v-model="ans19" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans19d" id="ans19d" v-model="ans19" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans19e" id="ans19e" v-model="ans19" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>20. Lakomislen/a sam i bezbrižan/a.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans20a" id="ans20a" v-model="ans20" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans20b" id="ans20b" v-model="ans20" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans20c" id="ans20c" v-model="ans20" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans20d" id="ans20d" v-model="ans20" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans20e" id="ans20e" v-model="ans20" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>21. Retko u bilo čemu preterujem.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans21a" id="ans21a" v-model="ans21" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans21b" id="ans21b" v-model="ans21" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans21c" id="ans21c" v-model="ans21" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans21d" id="ans21d" v-model="ans21" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans21e" id="ans21e" v-model="ans21" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>22. Često čeznem za uzbuđenjima.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans22a" id="ans22a" v-model="ans22" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans22b" id="ans22b" v-model="ans22" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans22c" id="ans22c" v-model="ans22" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans22d" id="ans22d" v-model="ans22" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans22e" id="ans22e" v-model="ans22" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>23. Uživam da se poigravam teorijama i apstraktnim idejama.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans23a" id="ans23a" v-model="ans23" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans23b" id="ans23b" v-model="ans23" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans23c" id="ans23c" v-model="ans23" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans23d" id="ans23d" v-model="ans23" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans23e" id="ans23e" v-model="ans23" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>24. Nije mi teško da se hvališem svojim talentima i dostignućima.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans24a" id="ans24a" v-model="ans24" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans24b" id="ans24b" v-model="ans24" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans24c" id="ans24c" v-model="ans24" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans24d" id="ans24d" v-model="ans24" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans24e" id="ans24e" v-model="ans24" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>25. Prilično dobro uspevam da sebi odredim takav tempo da sve završim na vreme.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans25a" id="ans25a" v-model="ans25" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans25b" id="ans25b" v-model="ans25" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans25c" id="ans25c" v-model="ans25" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans25d" id="ans25d" v-model="ans25" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans25e" id="ans25e" v-model="ans25" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>26. Često se osećam bespomoćno i želim da neko drugi reši moje probleme.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans26a" id="ans26a" v-model="ans26" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans26b" id="ans26b" v-model="ans26" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans26c" id="ans26c" v-model="ans26" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans26d" id="ans26d" v-model="ans26" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans26e" id="ans26e" v-model="ans26" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>27. Nikad nisam bukvalno poskakivao/la od radosti.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans27a" id="ans27a" v-model="ans27" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans27b" id="ans27b" v-model="ans27" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans27c" id="ans27c" v-model="ans27" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans27d" id="ans27d" v-model="ans27" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans27e" id="ans27e" v-model="ans27" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>28. Smatram da studente može samo da zbuni ako slušaju protivrečne govornike.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans28a" id="ans28a" v-model="ans28" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans28b" id="ans28b" v-model="ans28" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans28c" id="ans28c" v-model="ans28" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans28d" id="ans28d" v-model="ans28" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans28e" id="ans28e" v-model="ans28" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>29. Političke vođe bi trebalo da budu svesnije ljudske strane politike.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans29a" id="ans29a" v-model="ans29" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans29b" id="ans29b" v-model="ans29" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans29c" id="ans29c" v-model="ans29" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans29d" id="ans29d" v-model="ans29" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans29e" id="ans29e" v-model="ans29" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>30. Tokom godina učinio/la sam mnogo gluposti.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans30a" id="ans30a" v-model="ans30" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans30b" id="ans30b" v-model="ans30" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans30c" id="ans30c" v-model="ans30" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans30d" id="ans30d" v-model="ans30" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans30e" id="ans30e" v-model="ans30" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>31. Lako se preplašim.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans31a" id="ans31a" v-model="ans31" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans31b" id="ans31b" v-model="ans31" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans31c" id="ans31c" v-model="ans31" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans31d" id="ans31d" v-model="ans31" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans31e" id="ans31e" v-model="ans31" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>32. Nije mi baš neko zadovoljstvo da čavrljam sa ljudima.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans32a" id="ans32a" v-model="ans32" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans32b" id="ans32b" v-model="ans32" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans32c" id="ans32c" v-model="ans32" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans32d" id="ans32d" v-model="ans32" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans32e" id="ans32e" v-model="ans32" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>33. Trudim se da mislim realno i izbegavam maštanje.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans33a" id="ans33a" v-model="ans33" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans33b" id="ans33b" v-model="ans33" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans33c" id="ans33c" v-model="ans33" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans33d" id="ans33d" v-model="ans33" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans33e" id="ans33e" v-model="ans33" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>34. Verujem da je većina ljudi u suštini dobronamerna.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans34a" id="ans34a" v-model="ans34" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans34b" id="ans34b" v-model="ans34" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans34c" id="ans34c" v-model="ans34" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans34d" id="ans34d" v-model="ans34" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans34e" id="ans34e" v-model="ans34" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>35. Građanske dužnosti kao što je glasanje ne shvatam mnogo ozbiljno.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans35a" id="ans35a" v-model="ans35" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans35b" id="ans35b" v-model="ans35" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans35c" id="ans35c" v-model="ans35" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans35d" id="ans35d" v-model="ans35" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans35e" id="ans35e" v-model="ans35" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>36. Ja sam uravnotežena osoba.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans36a" id="ans36a" v-model="ans36" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans36b" id="ans36b" v-model="ans36" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans36c" id="ans36c" v-model="ans36" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans36d" id="ans36d" v-model="ans36" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans36e" id="ans36e" v-model="ans36" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>37. Volim da oko sebe imam mnogo ljudi.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans37a" id="ans37a" v-model="ans37" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans37b" id="ans37b" v-model="ans37" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans37c" id="ans37c" v-model="ans37" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans37d" id="ans37d" v-model="ans37" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans37e" id="ans37e" v-model="ans37" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>38. Ponekad me potpuno obuzme muzika koju slušam.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans38a" id="ans38a" v-model="ans38" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans38b" id="ans38b" v-model="ans38" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans38c" id="ans38c" v-model="ans38" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans38d" id="ans38d" v-model="ans38" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans38e" id="ans38e" v-model="ans38" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>39. Ako je potrebno, spreman/na sam da manipulišem ljudima kako bih dobio/la ono što želim.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans39a" id="ans39a" v-model="ans39" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans39b" id="ans39b" v-model="ans39" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans39c" id="ans39c" v-model="ans39" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans39d" id="ans39d" v-model="ans39" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans39e" id="ans39e" v-model="ans39" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>40. Svoje stvari držim urednim i čistim.</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans40a" id="ans40a" v-model="ans40" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans40b" id="ans40b" v-model="ans40" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans40c" id="ans40c" v-model="ans40" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans40d" id="ans40d" v-model="ans40" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans40e" id="ans40e" v-model="ans40" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>

    <div class="form-group">
      <h3>41. Ponekad se osećam potpuno bezvredno.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans41a" id="ans41a" v-model="ans41" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans41b" id="ans41b" v-model="ans41" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans41c" id="ans41c" v-model="ans41" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans41d" id="ans41d" v-model="ans41" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans41e" id="ans41e" v-model="ans41" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>42. Ponekad nisam toliko prodoran/na koliko bih trebao/la da budem.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans42a" id="ans42a" v-model="ans42" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans42b" id="ans42b" v-model="ans42" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans42c" id="ans42c" v-model="ans42" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans42d" id="ans42d" v-model="ans42" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans42e" id="ans42e" v-model="ans42" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>43. Retko doživljavam snažna osećanja.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans43a" id="ans43a" v-model="ans43" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans43b" id="ans43b" v-model="ans43" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans43c" id="ans43c" v-model="ans43" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans43d" id="ans43d" v-model="ans43" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans43e" id="ans43e" v-model="ans43" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>44. Trudim se da budem pristojan/na sa svakim koga sretnem.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans44a" id="ans44a" v-model="ans44" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans44b" id="ans44b" v-model="ans44" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans44c" id="ans44c" v-model="ans44" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans44d" id="ans44d" v-model="ans44" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans44e" id="ans44e" v-model="ans44" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>45. Ponekad nisam toliko savesan/na i pouzdan/na koliko bi trebalo da budem.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans45a" id="ans45a" v-model="ans45" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans45b" id="ans45b" v-model="ans45" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans45c" id="ans45c" v-model="ans45" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans45d" id="ans45d" v-model="ans45" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans45e" id="ans45e" v-model="ans45" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>46. Kada sam među ljudima, retko se osećam sputano.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans46a" id="ans46a" v-model="ans46" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans46b" id="ans46b" v-model="ans46" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans46c" id="ans46c" v-model="ans46" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans46d" id="ans46d" v-model="ans46" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans46e" id="ans46e" v-model="ans46" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>47. Kad nešto radim, radim to svim srcem.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans47a" id="ans47a" v-model="ans47" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans47b" id="ans47b" v-model="ans47" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans47c" id="ans47c" v-model="ans47" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans47d" id="ans47d" v-model="ans47" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans47e" id="ans47e" v-model="ans47" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>48. Mislim da je zanimljivo sticati nova znanja i nove hobije.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans48a" id="ans48a" v-model="ans48" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans48b" id="ans48b" v-model="ans48" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans48c" id="ans48c" v-model="ans48" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans48d" id="ans48d" v-model="ans48" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans48e" id="ans48e" v-model="ans48" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>49. Kada zatreba, umem da budem sarkastičan/na i oštar/a.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans49a" id="ans49a" v-model="ans49" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans49b" id="ans49b" v-model="ans49" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans49c" id="ans49c" v-model="ans49" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans49d" id="ans49d" v-model="ans49" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans49e" id="ans49e" v-model="ans49" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>50. Imam jasne ciljeve i trudim se da ih ostvarim sistematično.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans50a" id="ans50a" v-model="ans50" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans50b" id="ans50b" v-model="ans50" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans50c" id="ans50c" v-model="ans50" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans50d" id="ans50d" v-model="ans50" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans50e" id="ans50e" v-model="ans50" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>51. Imam teškoće da se oduprem svojim neobuzdanim željama.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans51a" id="ans51a" v-model="ans51" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans51b" id="ans51b" v-model="ans51" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans51c" id="ans51c" v-model="ans51" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans51d" id="ans51d" v-model="ans51" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans51e" id="ans51e" v-model="ans51" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>52. Ne bih uživao/la u praznicima provedenim u kockanju.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans52a" id="ans52a" v-model="ans52" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans52b" id="ans52b" v-model="ans52" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans52c" id="ans52c" v-model="ans52" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans52d" id="ans52d" v-model="ans52" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans52e" id="ans52e" v-model="ans52" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>53. Filozofske rasprave smatram dosadnim.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans53a" id="ans53a" v-model="ans53" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans53b" id="ans53b" v-model="ans53" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans53c" id="ans53c" v-model="ans53" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans53d" id="ans53d" v-model="ans53" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans53e" id="ans53e" v-model="ans53" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>54. Radije ne bih govorio/la o sebi i onom što sam postigao/la.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans54a" id="ans54a" v-model="ans54" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans54b" id="ans54b" v-model="ans54" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans54c" id="ans54c" v-model="ans54" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans54d" id="ans54d" v-model="ans54" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans54e" id="ans54e" v-model="ans54" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>55. Protraćim mnogo vremena pre nego što se dohvatim posla.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans55a" id="ans55a" v-model="ans55" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans55b" id="ans55b" v-model="ans55" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans55c" id="ans55c" v-model="ans55" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans55d" id="ans55d" v-model="ans55" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans55e" id="ans55e" v-model="ans55" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>56. Osećam da sam u stanju da se nosim sa većinom svojih problema.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans56a" id="ans56a" v-model="ans56" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans56b" id="ans56b" v-model="ans56" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans56c" id="ans56c" v-model="ans56" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans56d" id="ans56d" v-model="ans56" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans56e" id="ans56e" v-model="ans56" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>57. Ponekad sam osećao/la veliku radost ili oduševljenje.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans57a" id="ans57a" v-model="ans57" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans57b" id="ans57b" v-model="ans57" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans57c" id="ans57c" v-model="ans57" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans57d" id="ans57d" v-model="ans57" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans57e" id="ans57e" v-model="ans57" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>58. Smatram da je potrebno menjati zakone i socijalnu politiku kako bi odražavali potrebe sveta koji se menja.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans58a" id="ans58a" v-model="ans58" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans58b" id="ans58b" v-model="ans58" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans58c" id="ans58c" v-model="ans58" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans58d" id="ans58d" v-model="ans58" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans58e" id="ans58e" v-model="ans58" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>59. Tvrdoglav/a sam i nepopustljiv/a u svojim stavovima.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans59a" id="ans59a" v-model="ans59" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans59b" id="ans59b" v-model="ans59" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans59c" id="ans59c" v-model="ans59" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans59d" id="ans59d" v-model="ans59" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans59e" id="ans59e" v-model="ans59" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>60. Dobro promislim pre nego što donesem odluku.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans60a" id="ans60a" v-model="ans60" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans60b" id="ans60b" v-model="ans60" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans60c" id="ans60c" v-model="ans60" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans60d" id="ans60d" v-model="ans60" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans60e" id="ans60e" v-model="ans60" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>61. Retko kad se osećam preplašeno i anksiozno.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans61a" id="ans61a" v-model="ans61" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans61b" id="ans61b" v-model="ans61" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans61c" id="ans61c" v-model="ans61" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans61d" id="ans61d" v-model="ans61" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans61e" id="ans61e" v-model="ans61" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>62. Poznat/a sam kao topla i prijatna osoba.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans62a" id="ans62a" v-model="ans62" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans62b" id="ans62b" v-model="ans62" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans62c" id="ans62c" v-model="ans62" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans62d" id="ans62d" v-model="ans62" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans62e" id="ans62e" v-model="ans62" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>63. Imam bujnu maštu.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans63a" id="ans63a" v-model="ans63" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans63b" id="ans63b" v-model="ans63" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans63c" id="ans63c" v-model="ans63" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans63d" id="ans63d" v-model="ans63" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans63e" id="ans63e" v-model="ans63" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>64. Verujem da će vas većina ljudi iskoristiti ako im to dopustite.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans64a" id="ans64a" v-model="ans64" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans64b" id="ans64b" v-model="ans64" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans64c" id="ans64c" v-model="ans64" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans64d" id="ans64d" v-model="ans64" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans64e" id="ans64e" v-model="ans64" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>65. Trudim se da budem dobro obavešten/a i obično donosim dobre odluke.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans65a" id="ans65a" v-model="ans65" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans65b" id="ans65b" v-model="ans65" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans65c" id="ans65c" v-model="ans65" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans65d" id="ans65d" v-model="ans65" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans65e" id="ans65e" v-model="ans65" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>66. Poznat/a sam kao žestoka i nagla osoba.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans66a" id="ans66a" v-model="ans66" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans66b" id="ans66b" v-model="ans66" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans66c" id="ans66c" v-model="ans66" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans66d" id="ans66d" v-model="ans66" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans66e" id="ans66e" v-model="ans66" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>67. Obično više volim da radim sam/a.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans67a" id="ans67a" v-model="ans67" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans67b" id="ans67b" v-model="ans67" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans67c" id="ans67c" v-model="ans67" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans67d" id="ans67d" v-model="ans67" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans67e" id="ans67e" v-model="ans67" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>68. Dosadno mi je da gledam balet ili moderan ples.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans68a" id="ans68a" v-model="ans68" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans68b" id="ans68b" v-model="ans68" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans68c" id="ans68c" v-model="ans68" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans68d" id="ans68d" v-model="ans68" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans68e" id="ans68e" v-model="ans68" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>69. Ne bih mogao/la nikoga da prevarim, čak i kada bih to želeo/la da učinim.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans69a" id="ans69a" v-model="ans69" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans69b" id="ans69b" v-model="ans69" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans69c" id="ans69c" v-model="ans69" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans69d" id="ans69d" v-model="ans69" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans69e" id="ans69e" v-model="ans69" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>70. Nisam naročito metodična osoba.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans70a" id="ans70a" v-model="ans70" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans70b" id="ans70b" v-model="ans70" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans70c" id="ans70c" v-model="ans70" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans70d" id="ans70d" v-model="ans70" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans70e" id="ans70e" v-model="ans70" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>71. Retko sam tužan/na ili potišten/a.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans71a" id="ans71a" v-model="ans71" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans71b" id="ans71b" v-model="ans71" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans71c" id="ans71c" v-model="ans71" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans71d" id="ans71d" v-model="ans71" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans71e" id="ans71e" v-model="ans71" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>72. Često sam bio/la vođa grupa kojima sam pripadao/la.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans72a" id="ans72a" v-model="ans72" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans72b" id="ans72b" v-model="ans72" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans72c" id="ans72c" v-model="ans72" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans72d" id="ans72d" v-model="ans72" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans72e" id="ans72e" v-model="ans72" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>73. Važno mi je šta osećam prema stvarima i događajima.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans73a" id="ans73a" v-model="ans73" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans73b" id="ans73b" v-model="ans73" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans73c" id="ans73c" v-model="ans73" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans73d" id="ans73d" v-model="ans73" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans73e" id="ans73e" v-model="ans73" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>74. Neki ljudi me smatraju hladnom i proračunatom osobom.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans74a" id="ans74a" v-model="ans74" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans74b" id="ans74b" v-model="ans74" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans74c" id="ans74c" v-model="ans74" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans74d" id="ans74d" v-model="ans74" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans74e" id="ans74e" v-model="ans74" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>75. Svoje dugove plaćam bez odlaganja i u celosti.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans75a" id="ans75a" v-model="ans75" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans75b" id="ans75b" v-model="ans75" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans75c" id="ans75c" v-model="ans75" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans75d" id="ans75d" v-model="ans75" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans75e" id="ans75e" v-model="ans75" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>76. Događalo mi se da sam bio/la toliko posramljen/a da sam želeo/la da me nema.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans76a" id="ans76a" v-model="ans76" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans76b" id="ans76b" v-model="ans76" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans76c" id="ans76c" v-model="ans76" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans76d" id="ans76d" v-model="ans76" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans76e" id="ans76e" v-model="ans76" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>77. Obično radim sporo, ali temeljno.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans77a" id="ans77a" v-model="ans77" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans77b" id="ans77b" v-model="ans77" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans77c" id="ans77c" v-model="ans77" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans77d" id="ans77d" v-model="ans77" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans77e" id="ans77e" v-model="ans77" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>78. Kada jednom nađem pravi način obavljanja nekog posla, ostajem pri tome.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans78a" id="ans78a" v-model="ans78" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans78b" id="ans78b" v-model="ans78" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans78c" id="ans78c" v-model="ans78" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans78d" id="ans78d" v-model="ans78" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans78e" id="ans78e" v-model="ans78" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>79. Oklevam da pokažem svoju ljutnju, čak i kada je opravdana.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans79a" id="ans79a" v-model="ans79" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans79b" id="ans79b" v-model="ans79" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans79c" id="ans79c" v-model="ans79" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans79d" id="ans79d" v-model="ans79" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans79e" id="ans79e" v-model="ans79" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>80. Kada počnem da radim na sebi obično odustajem posle nekoliko dana.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans80a" id="ans80a" v-model="ans80" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans80b" id="ans80b" v-model="ans80" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans80c" id="ans80c" v-model="ans80" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans80d" id="ans80d" v-model="ans80" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans80e" id="ans80e" v-model="ans80" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>

    <div class="form-group">
      <h3>81. Nije mi teško da odolim iskušenju.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans81a" id="ans81a" v-model="ans81" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans81b" id="ans81b" v-model="ans81" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans81c" id="ans81c" v-model="ans81" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans81d" id="ans81d" v-model="ans81" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans81e" id="ans81e" v-model="ans81" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>82. Ponekad sam radio/la stvari samo zbog zabave ili uzbuđenja.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans82a" id="ans82a" v-model="ans82" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans82b" id="ans82b" v-model="ans82" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans82c" id="ans82c" v-model="ans82" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans82d" id="ans82d" v-model="ans82" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans82e" id="ans82e" v-model="ans82" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>83. Umem da rešavam probleme ili zagonetke.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans83a" id="ans83a" v-model="ans83" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans83b" id="ans83b" v-model="ans83" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans83c" id="ans83c" v-model="ans83" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans83d" id="ans83d" v-model="ans83" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans83e" id="ans83e" v-model="ans83" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>84. Bolji/a sam od većine ljudi i to znam.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans84a" id="ans84a" v-model="ans84" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans84b" id="ans84b" v-model="ans84" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans84c" id="ans84c" v-model="ans84" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans84d" id="ans84d" v-model="ans84" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans84e" id="ans84e" v-model="ans84" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>85. Ja sam produktivna osoba koja uvek završi započeti posao.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans85a" id="ans85a" v-model="ans85" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans85b" id="ans85b" v-model="ans85" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans85c" id="ans85c" v-model="ans85" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans85d" id="ans85d" v-model="ans85" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans85e" id="ans85e" v-model="ans85" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>86. Kada sam pod velikim stresom, ponekad se osećam kao da ću se „raspasti“, „pući“.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans86a" id="ans86a" v-model="ans86" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans86b" id="ans86b" v-model="ans86" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans86c" id="ans86c" v-model="ans86" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans86d" id="ans86d" v-model="ans86" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans86e" id="ans86e" v-model="ans86" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>87. Nisam razdragani optimista.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans87a" id="ans87a" v-model="ans87" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans87b" id="ans87b" v-model="ans87" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans87c" id="ans87c" v-model="ans87" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans87d" id="ans87d" v-model="ans87" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans87e" id="ans87e" v-model="ans87" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>88. Pri donošenju odluka o moralnim pitanjima trebalo bi da se ugledamo na verske autoritete.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans88a" id="ans88a" v-model="ans88" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans88b" id="ans88b" v-model="ans88" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans88c" id="ans88c" v-model="ans88" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans88d" id="ans88d" v-model="ans88" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans88e" id="ans88e" v-model="ans88" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>89. Nikad ne možemo da učinimo previše za siromašne i starije ljude.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans89a" id="ans89a" v-model="ans89" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans89b" id="ans89b" v-model="ans89" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans89c" id="ans89c" v-model="ans89" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans89d" id="ans89d" v-model="ans89" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans89e" id="ans89e" v-model="ans89" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>90. Povremeno prvo nešto učinim, a tek posle razmislim.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans90a" id="ans90a" v-model="ans90" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans90b" id="ans90b" v-model="ans90" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans90c" id="ans90c" v-model="ans90" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans90d" id="ans90d" v-model="ans90" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans90e" id="ans90e" v-model="ans90" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>91. Često sam napet/a i živčan/a.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans91a" id="ans91a" v-model="ans91" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans91b" id="ans91b" v-model="ans91" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans91c" id="ans91c" v-model="ans91" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans91d" id="ans91d" v-model="ans91" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans91e" id="ans91e" v-model="ans91" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>92. Mnogi me smatraju pomalo hladnim/om i distanciranim/om.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans92a" id="ans92a" v-model="ans92" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans92b" id="ans92b" v-model="ans92" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans92c" id="ans92c" v-model="ans92" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans92d" id="ans92d" v-model="ans92" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans92e" id="ans92e" v-model="ans92" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>93. Ne volim da gubim vreme sanjareći.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans93a" id="ans93a" v-model="ans93" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans93b" id="ans93b" v-model="ans93" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans93c" id="ans93c" v-model="ans93" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans93d" id="ans93d" v-model="ans93" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans93e" id="ans93e" v-model="ans93" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>94. Verujem da je većina ljudi sa kojima imam posla poštena i da zaslužuje poverenje.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans94a" id="ans94a" v-model="ans94" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans94b" id="ans94b" v-model="ans94" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans94c" id="ans94c" v-model="ans94" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans94d" id="ans94d" v-model="ans94" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans94e" id="ans94e" v-model="ans94" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>95. Često se upuštam u nešto nedovoljno pripremljen/a.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans95a" id="ans95a" v-model="ans95" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans95b" id="ans95b" v-model="ans95" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans95c" id="ans95c" v-model="ans95" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans95d" id="ans95d" v-model="ans95" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans95e" id="ans95e" v-model="ans95" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>96. Ne smatraju me uvredljivom ili preterano osetljivom osobom.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans96a" id="ans96a" v-model="ans96" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans96b" id="ans96b" v-model="ans96" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans96c" id="ans96c" v-model="ans96" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans96d" id="ans96d" v-model="ans96" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans96e" id="ans96e" v-model="ans96" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>97. Ako sam duže vreme sam/a, zaista počinjem da osećam potrebu za drugim ljudima.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans97a" id="ans97a" v-model="ans97" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans97b" id="ans97b" v-model="ans97" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans97c" id="ans97c" v-model="ans97" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans97d" id="ans97d" v-model="ans97" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans97e" id="ans97e" v-model="ans97" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>98. Zanimaju me oblici koje nalazim u umetnosti i prirodi.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans98a" id="ans98a" v-model="ans98" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans98b" id="ans98b" v-model="ans98" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans98c" id="ans98c" v-model="ans98" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans98d" id="ans98d" v-model="ans98" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans98e" id="ans98e" v-model="ans98" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>99. Biti savršeno pošten je loš način vođenja poslova.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans99a" id="ans99a" v-model="ans99" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans99b" id="ans99b" v-model="ans99" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans99c" id="ans99c" v-model="ans99" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans99d" id="ans99d" v-model="ans99" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans99e" id="ans99e" v-model="ans99" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>100. Volim da držim sve na svom mestu kako bih znao/la gde je šta.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans100a" id="ans100a" v-model="ans100" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans100b" id="ans100b" v-model="ans100" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans100c" id="ans100c" v-model="ans100" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans100d" id="ans100d" v-model="ans100" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans100e" id="ans100e" v-model="ans100" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>101. Događalo se da se osećam jako krivim ili grešnim.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans101a" id="ans101a" v-model="ans101" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans101b" id="ans101b" v-model="ans101" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans101c" id="ans101c" v-model="ans101" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans101d" id="ans101d" v-model="ans101" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans101e" id="ans101e" v-model="ans101" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>102. Na sastancima obično prepuštam drugima da govore.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans102a" id="ans102a" v-model="ans102" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans102b" id="ans102b" v-model="ans102" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans102c" id="ans102c" v-model="ans102" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans102d" id="ans102d" v-model="ans102" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans102e" id="ans102e" v-model="ans102" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>103. Retko pridajem posebnu pažnju svojim trenutnim osećanjima.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans103a" id="ans103a" v-model="ans103" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans103b" id="ans103b" v-model="ans103" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans103c" id="ans103c" v-model="ans103" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans103d" id="ans103d" v-model="ans103" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans103e" id="ans103e" v-model="ans103" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>104. Trudim se da budem pažljiv/a i obziran/a.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans104a" id="ans104a" v-model="ans104" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans104b" id="ans104b" v-model="ans104" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans104c" id="ans104c" v-model="ans104" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans104d" id="ans104d" v-model="ans104" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans104e" id="ans104e" v-model="ans104" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>105. Ponekad varam kada slažem pasijans.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans105a" id="ans105a" v-model="ans105" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans105b" id="ans105b" v-model="ans105" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans105c" id="ans105c" v-model="ans105" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans105d" id="ans105d" v-model="ans105" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans105e" id="ans105e" v-model="ans105" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>106. Nije mi previše neugodno ako me ljudi ismejavaju i zadirkuju.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans106a" id="ans106a" v-model="ans106" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans106b" id="ans106b" v-model="ans106" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans106c" id="ans106c" v-model="ans106" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans106d" id="ans106d" v-model="ans106" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans106e" id="ans106e" v-model="ans106" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>107. Često osećam kao da pucam od energije.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans107a" id="ans107a" v-model="ans107" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans107b" id="ans107b" v-model="ans107" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans107c" id="ans107c" v-model="ans107" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans107d" id="ans107d" v-model="ans107" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans107e" id="ans107e" v-model="ans107" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>108. Često probam novu i nama stranu hranu.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans108a" id="ans108a" v-model="ans108" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans108b" id="ans108b" v-model="ans108" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans108c" id="ans108c" v-model="ans108" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans108d" id="ans108d" v-model="ans108" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans108e" id="ans108e" v-model="ans108" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>109. Ako mi se neko ne sviđa, ja mu to dam do znanja.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans109a" id="ans109a" v-model="ans109" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans109b" id="ans109b" v-model="ans109" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans109c" id="ans109c" v-model="ans109" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans109d" id="ans109d" v-model="ans109" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans109e" id="ans109e" v-model="ans109" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>110. Jako se trudim da ostvarim svoje ciljeve.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans110a" id="ans110a" v-model="ans110" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans110b" id="ans110b" v-model="ans110" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans110c" id="ans110c" v-model="ans110" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans110d" id="ans110d" v-model="ans110" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans110e" id="ans110e" v-model="ans110" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>111. Kada jedem svoje omiljno jelo, umem da se prejedem.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans111a" id="ans111a" v-model="ans111" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans111b" id="ans111b" v-model="ans111" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans111c" id="ans111c" v-model="ans111" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans111d" id="ans111d" v-model="ans111" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans111e" id="ans111e" v-model="ans111" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>112. Trudim se da izbegnem šokantne i zastrašujuće filmove.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans112a" id="ans112a" v-model="ans112" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans112b" id="ans112b" v-model="ans112" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans112c" id="ans112c" v-model="ans112" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans112d" id="ans112d" v-model="ans112" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans112e" id="ans112e" v-model="ans112" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>113. Ponekad izgubim interesovanje kada ljudi govore o vrlo apstraktnim, teorijskim stvarima.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans113a" id="ans113a" v-model="ans113" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans113b" id="ans113b" v-model="ans113" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans113c" id="ans113c" v-model="ans113" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans113d" id="ans113d" v-model="ans113" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans113e" id="ans113e" v-model="ans113" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>114. Trudim se da budem skroman/na.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans114a" id="ans114a" v-model="ans114" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans114b" id="ans114b" v-model="ans114" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans114c" id="ans114c" v-model="ans114" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans114d" id="ans114d" v-model="ans114" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans114e" id="ans114e" v-model="ans114" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>115. Teško mi je da se nateram da uradim ono što treba.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans115a" id="ans115a" v-model="ans115" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans115b" id="ans115b" v-model="ans115" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans115c" id="ans115c" v-model="ans115" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans115d" id="ans115d" v-model="ans115" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans115e" id="ans115e" v-model="ans115" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>116. U kritičnim situacijama ostajem priseban/na.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans116a" id="ans116a" v-model="ans116" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans116b" id="ans116b" v-model="ans116" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans116c" id="ans116c" v-model="ans116" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans116d" id="ans116d" v-model="ans116" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans116e" id="ans116e" v-model="ans116" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>117. Ponekad blistam od sreće.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans117a" id="ans117a" v-model="ans117" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans117b" id="ans117b" v-model="ans117" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans117c" id="ans117c" v-model="ans117" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans117d" id="ans117d" v-model="ans117" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans117e" id="ans117e" v-model="ans117" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>118. Smatram da različita shvatanja o dobru i zlu koja imaju ljudi u drugim društvima mogu za njih biti valjana.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans118a" id="ans118a" v-model="ans118" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans118b" id="ans118b" v-model="ans118" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans118c" id="ans118c" v-model="ans118" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans118d" id="ans118d" v-model="ans118" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans118e" id="ans118e" v-model="ans118" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>119. Ne saosećam sa uličnim prosjacima.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans119a" id="ans119a" v-model="ans119" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans119b" id="ans119b" v-model="ans119" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans119c" id="ans119c" v-model="ans119" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans119d" id="ans119d" v-model="ans119" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans119e" id="ans119e" v-model="ans119" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>120. Pre nego što nešto preduzmem, uvek razmislim o posledicama.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans120a" id="ans120a" v-model="ans120" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans120b" id="ans120b" v-model="ans120" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans120c" id="ans120c" v-model="ans120" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans120d" id="ans120d" v-model="ans120" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans120e" id="ans120e" v-model="ans120" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>

    <div class="form-group">
      <h3>121. Retko strepim zbog budućnosti.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans121a" id="ans121a" v-model="ans121" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans121b" id="ans121b" v-model="ans121" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans121c" id="ans121c" v-model="ans121" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans121d" id="ans121d" v-model="ans121" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans121e" id="ans121e" v-model="ans121" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>122. Zaista uživam da razgovaram sa ljudima.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans122a" id="ans122a" v-model="ans122" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans122b" id="ans122b" v-model="ans122" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans122c" id="ans122c" v-model="ans122" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans122d" id="ans122d" v-model="ans122" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans122e" id="ans122e" v-model="ans122" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>123. Uživam da se usmerim na neku maštariju ili neko sanjarenje i istražim sve mogućnosti koje mi pruža.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans123a" id="ans123a" v-model="ans123" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans123b" id="ans123b" v-model="ans123" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans123c" id="ans123c" v-model="ans123" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans123d" id="ans123d" v-model="ans123" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans123e" id="ans123e" v-model="ans123" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>124. Sumnjičav/a sam kad neko učini nešto dobro za mene.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans124a" id="ans124a" v-model="ans124" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans124b" id="ans124b" v-model="ans124" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans124c" id="ans124c" v-model="ans124" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans124d" id="ans124d" v-model="ans124" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans124e" id="ans124e" v-model="ans124" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>125. Ponosim se svojim zdravim rasuđivanjem.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans125a" id="ans125a" v-model="ans125" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans125b" id="ans125b" v-model="ans125" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans125c" id="ans125c" v-model="ans125" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans125d" id="ans125d" v-model="ans125" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans125e" id="ans125e" v-model="ans125" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>126. Često se razbesnim na ljude sa kojima moram da imam posla.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans126a" id="ans126a" v-model="ans126" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans126b" id="ans126b" v-model="ans126" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans126c" id="ans126c" v-model="ans126" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans126d" id="ans126d" v-model="ans126" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans126e" id="ans126e" v-model="ans126" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>127. Više mi se sviđaju poslovi koje mogu da radim sam/a, bez dosađivanja drugih ljudi.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans127a" id="ans127a" v-model="ans127" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans127b" id="ans127b" v-model="ans127" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans127c" id="ans127c" v-model="ans127" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans127d" id="ans127d" v-model="ans127" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans127e" id="ans127e" v-model="ans127" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>128. Poezija na mene deluje malo ili nimalo.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans128a" id="ans128a" v-model="ans128" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans128b" id="ans128b" v-model="ans128" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans128c" id="ans128c" v-model="ans128" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans128d" id="ans128d" v-model="ans128" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans128e" id="ans128e" v-model="ans128" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>129. Bilo bi mi mrsko da me smatraju licemerom.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans129a" id="ans129a" v-model="ans129" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans129b" id="ans129b" v-model="ans129" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans129c" id="ans129c" v-model="ans129" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans129d" id="ans129d" v-model="ans129" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans129e" id="ans129e" v-model="ans129" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>130. Izgleda da uopšte nisam u stanju da se organizujem.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans130a" id="ans130a" v-model="ans130" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans130b" id="ans130b" v-model="ans130" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans130c" id="ans130c" v-model="ans130" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans130d" id="ans130d" v-model="ans130" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans130e" id="ans130e" v-model="ans130" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>131. Sklon/a sam da okrivim sebe kada bilo šta pođe naopako.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans131a" id="ans131a" v-model="ans131" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans131b" id="ans131b" v-model="ans131" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans131c" id="ans131c" v-model="ans131" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans131d" id="ans131d" v-model="ans131" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans131e" id="ans131e" v-model="ans131" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>132. Prilikom donošenja odluka drugi ljudi mi se često obraćaju.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans132a" id="ans132a" v-model="ans132" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans132b" id="ans132b" v-model="ans132" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans132c" id="ans132c" v-model="ans132" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans132d" id="ans132d" v-model="ans132" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans132e" id="ans132e" v-model="ans132" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>133. Doživljavam širok raspon osećanja.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans133a" id="ans133a" v-model="ans133" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans133b" id="ans133b" v-model="ans133" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans133c" id="ans133c" v-model="ans133" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans133d" id="ans133d" v-model="ans133" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans133e" id="ans133e" v-model="ans133" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>134. Nisam poznat/a po svojoj velikodušnosti.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans134a" id="ans134a" v-model="ans134" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans134b" id="ans134b" v-model="ans134" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans134c" id="ans134c" v-model="ans134" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans134d" id="ans134d" v-model="ans134" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans134e" id="ans134e" v-model="ans134" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>135. Kada preuzmem neku obavezu, na mene se uvek može računati da ću je izvršiti.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans135a" id="ans135a" v-model="ans135" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans135b" id="ans135b" v-model="ans135" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans135c" id="ans135c" v-model="ans135" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans135d" id="ans135d" v-model="ans135" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans135e" id="ans135e" v-model="ans135" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>136. Često se osećam manje vrednim/om od drugih ljudi.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans136a" id="ans136a" v-model="ans136" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans136b" id="ans136b" v-model="ans136" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans136c" id="ans136c" v-model="ans136" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans136d" id="ans136d" v-model="ans136" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans136e" id="ans136e" v-model="ans136" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>137. Nisam tako brz/a i živahan/a kao drugi ljudi.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans137a" id="ans137a" v-model="ans137" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans137b" id="ans137b" v-model="ans137" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans137c" id="ans137c" v-model="ans137" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans137d" id="ans137d" v-model="ans137" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans137e" id="ans137e" v-model="ans137" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>138. Sklon/a sam tome da provodim svoje vreme u poznatoj okolini.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans138a" id="ans138a" v-model="ans138" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans138b" id="ans138b" v-model="ans138" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans138c" id="ans138c" v-model="ans138" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans138d" id="ans138d" v-model="ans138" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans138e" id="ans138e" v-model="ans138" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>139. Kad me neko uvredi, ja se jednostavno trudim da oprostim i zaboravim.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans139a" id="ans139a" v-model="ans139" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans139b" id="ans139b" v-model="ans139" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans139c" id="ans139c" v-model="ans139" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans139d" id="ans139d" v-model="ans139" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans139e" id="ans139e" v-model="ans139" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>140. Ne osećam kao da baš moram nešto da postignem.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans140a" id="ans140a" v-model="ans140" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans140b" id="ans140b" v-model="ans140" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans140c" id="ans140c" v-model="ans140" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans140d" id="ans140d" v-model="ans140" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans140e" id="ans140e" v-model="ans140" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>141. Retko popuštam svojim porivima.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans141a" id="ans141a" v-model="ans141" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans141b" id="ans141b" v-model="ans141" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans141c" id="ans141c" v-model="ans141" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans141d" id="ans141d" v-model="ans141" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans141e" id="ans141e" v-model="ans141" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>142. Volim da budem tamo gde se nešto događa.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans142a" id="ans142a" v-model="ans142" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans142b" id="ans142b" v-model="ans142" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans142c" id="ans142c" v-model="ans142" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans142d" id="ans142d" v-model="ans142" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans142e" id="ans142e" v-model="ans142" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>143. Uživam da rešavam teške zagonetke.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans143a" id="ans143a" v-model="ans143" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans143b" id="ans143b" v-model="ans143" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans143c" id="ans143c" v-model="ans143" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans143d" id="ans143d" v-model="ans143" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans143e" id="ans143e" v-model="ans143" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>144. Imam vrlo visoko mišljenje o sebi.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans144a" id="ans144a" v-model="ans144" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans144b" id="ans144b" v-model="ans144" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans144c" id="ans144c" v-model="ans144" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans144d" id="ans144d" v-model="ans144" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans144e" id="ans144e" v-model="ans144" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>145. Kad jednom nešto započnem, gotovo uvek to i dovršim.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans145a" id="ans145a" v-model="ans145" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans145b" id="ans145b" v-model="ans145" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans145c" id="ans145c" v-model="ans145" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans145d" id="ans145d" v-model="ans145" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans145e" id="ans145e" v-model="ans145" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>146. Često se teško odlučujem.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans146a" id="ans146a" v-model="ans146" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans146b" id="ans146b" v-model="ans146" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans146c" id="ans146c" v-model="ans146" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans146d" id="ans146d" v-model="ans146" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans146e" id="ans146e" v-model="ans146" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>147. Ne smatram se naročito „bezbrižnom“ osobom.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans147a" id="ans147a" v-model="ans147" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans147b" id="ans147b" v-model="ans147" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans147c" id="ans147c" v-model="ans147" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans147d" id="ans147d" v-model="ans147" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans147e" id="ans147e" v-model="ans147" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>148. Smatram da je privrženost vlastitim idealima i načelima važnija od otvorenosti prema novome.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans148a" id="ans148a" v-model="ans148" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans148b" id="ans148b" v-model="ans148" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans148c" id="ans148c" v-model="ans148" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans148d" id="ans148d" v-model="ans148" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans148e" id="ans148e" v-model="ans148" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>149. Ljudske potrebe moraju uvek imati prednost pred ekonomskim razlozima.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans149a" id="ans149a" v-model="ans149" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans149b" id="ans149b" v-model="ans149" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans149c" id="ans149c" v-model="ans149" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans149d" id="ans149d" v-model="ans149" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans149e" id="ans149e" v-model="ans149" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>150. Često učinim nešto povodeći se za trenutnom pobudom.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans150a" id="ans150a" v-model="ans150" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans150b" id="ans150b" v-model="ans150" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans150c" id="ans150c" v-model="ans150" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans150d" id="ans150d" v-model="ans150" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans150e" id="ans150e" v-model="ans150" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>151. Često sam zabrinut/a zbog stvari koje bi mogle poći naopako.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans151a" id="ans151a" v-model="ans151" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans151b" id="ans151b" v-model="ans151" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans151c" id="ans151c" v-model="ans151" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans151d" id="ans151d" v-model="ans151" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans151e" id="ans151e" v-model="ans151" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>152. Ne pada mi teško da se nasmešim i budem otvoren/a prema ljudima koje ne poznajem.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans152a" id="ans152a" v-model="ans152" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans152b" id="ans152b" v-model="ans152" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans152c" id="ans152c" v-model="ans152" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans152d" id="ans152d" v-model="ans152" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans152e" id="ans152e" v-model="ans152" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>153. Ako osetim da se prepuštam sanjarenju, obično počnem nečim da se bavim ili se usmerim na neki posao.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans153a" id="ans153a" v-model="ans153" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans153b" id="ans153b" v-model="ans153" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans153c" id="ans153c" v-model="ans153" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans153d" id="ans153d" v-model="ans153" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans153e" id="ans153e" v-model="ans153" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>154. Moja prva reakcija je da verujem ljudima.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans154a" id="ans154a" v-model="ans154" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans154b" id="ans154b" v-model="ans154" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans154c" id="ans154c" v-model="ans154" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans154d" id="ans154d" v-model="ans154" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans154e" id="ans154e" v-model="ans154" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>155. Čini mi se da ni u čemu nisam potpuno uspešan/a.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans155a" id="ans155a" v-model="ans155" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans155b" id="ans155b" v-model="ans155" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans155c" id="ans155c" v-model="ans155" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans155d" id="ans155d" v-model="ans155" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans155e" id="ans155e" v-model="ans155" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>156. Mene je teško razbesneti.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans156a" id="ans156a" v-model="ans156" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans156b" id="ans156b" v-model="ans156" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans156c" id="ans156c" v-model="ans156" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans156d" id="ans156d" v-model="ans156" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans156e" id="ans156e" v-model="ans156" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>157. Radije bih proveo/la praznike u nekom poznatom mestu na obali nego u usamljenoj šumskoj kolibi.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans157a" id="ans157a" v-model="ans157" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans157b" id="ans157b" v-model="ans157" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans157c" id="ans157c" v-model="ans157" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans157d" id="ans157d" v-model="ans157" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans157e" id="ans157e" v-model="ans157" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>158. Određena vrsta muzike beskrajno me očarava.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans158a" id="ans158a" v-model="ans158" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans158b" id="ans158b" v-model="ans158" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans158c" id="ans158c" v-model="ans158" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans158d" id="ans158d" v-model="ans158" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans158e" id="ans158e" v-model="ans158" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>159. Ponekad navedem ljude da rade ono što želim.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans159a" id="ans159a" v-model="ans159" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans159b" id="ans159b" v-model="ans159" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans159c" id="ans159c" v-model="ans159" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans159d" id="ans159d" v-model="ans159" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans159e" id="ans159e" v-model="ans159" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>160. Pomalo sam sitničav/a i zahtevan/a.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans160a" id="ans160a" v-model="ans160" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans160b" id="ans160b" v-model="ans160" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans160c" id="ans160c" v-model="ans160" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans160d" id="ans160d" v-model="ans160" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans160e" id="ans160e" v-model="ans160" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>


    <div class="form-group">
      <h3>161. Imam loše mišljenje o sebi.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans161a" id="ans161a" v-model="ans161" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans161b" id="ans161b" v-model="ans161" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans161c" id="ans161c" v-model="ans161" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans161d" id="ans161d" v-model="ans161" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans161e" id="ans161e" v-model="ans161" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>162. Radije bih išao/la svojim putem nego vodio/la druge ljude.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans162a" id="ans162a" v-model="ans162" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans162b" id="ans162b" v-model="ans162" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans162c" id="ans162c" v-model="ans162" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans162d" id="ans162d" v-model="ans162" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans162e" id="ans162e" v-model="ans162" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>163. Retko primećujem raspoloženja ili osećanja koje izazivaju različita okruženja.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans163a" id="ans163a" v-model="ans163" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans163b" id="ans163b" v-model="ans163" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans163c" id="ans163c" v-model="ans163" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans163d" id="ans163d" v-model="ans163" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans163e" id="ans163e" v-model="ans163" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>164. Većina ljudi koje poznajem mi je naklonjena.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans164a" id="ans164a" v-model="ans164" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans164b" id="ans164b" v-model="ans164" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans164c" id="ans164c" v-model="ans164" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans164d" id="ans164d" v-model="ans164" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans164e" id="ans164e" v-model="ans164" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>165. Strogo se držim svojih etičkih načela.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans165a" id="ans165a" v-model="ans165" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans165b" id="ans165b" v-model="ans165" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans165c" id="ans165c" v-model="ans165" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans165d" id="ans165d" v-model="ans165" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans165e" id="ans165e" v-model="ans165" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>166. Osećam se prijatno u prisustvu nadređenih ili drugih autoriteta.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans166a" id="ans166a" v-model="ans166" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans166b" id="ans166b" v-model="ans166" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans166c" id="ans166c" v-model="ans166" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans166d" id="ans166d" v-model="ans166" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans166e" id="ans166e" v-model="ans166" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>167. Obično ostavljam utisak užurbane osobe.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans167a" id="ans167a" v-model="ans167" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans167b" id="ans167b" v-model="ans167" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans167c" id="ans167c" v-model="ans167" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans167d" id="ans167d" v-model="ans167" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans167e" id="ans167e" v-model="ans167" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>168. Ponekad menjam nešto po kući samo da isprobam nešto novo.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans168a" id="ans168a" v-model="ans168" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans168b" id="ans168b" v-model="ans168" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans168c" id="ans168c" v-model="ans168" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans168d" id="ans168d" v-model="ans168" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans168e" id="ans168e" v-model="ans168" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>169. Ako neko započne svađu, spreman/a sam da uzvratim.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans169a" id="ans169a" v-model="ans169" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans169b" id="ans169b" v-model="ans169" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans169c" id="ans169c" v-model="ans169" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans169d" id="ans169d" v-model="ans169" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans169e" id="ans169e" v-model="ans169" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>170. Trudim se da postignem sve što mogu.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans170a" id="ans170a" v-model="ans170" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans170b" id="ans170b" v-model="ans170" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans170c" id="ans170c" v-model="ans170" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans170d" id="ans170d" v-model="ans170" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans170e" id="ans170e" v-model="ans170" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>171. Ponekad jedem dok mi ne pozli.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans171a" id="ans171a" v-model="ans171" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans171b" id="ans171b" v-model="ans171" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans171c" id="ans171c" v-model="ans171" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans171d" id="ans171d" v-model="ans171" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans171e" id="ans171e" v-model="ans171" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>172. Volim uzbuđenja brze vožnje.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans172a" id="ans172a" v-model="ans172" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans172b" id="ans172b" v-model="ans172" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans172c" id="ans172c" v-model="ans172" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans172d" id="ans172d" v-model="ans172" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans172e" id="ans172e" v-model="ans172" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>173. Ne zanimaju me razmišljanja o prirodi svemira ili ljudskoj sudbini.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans173a" id="ans173a" v-model="ans173" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans173b" id="ans173b" v-model="ans173" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans173c" id="ans173c" v-model="ans173" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans173d" id="ans173d" v-model="ans173" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans173e" id="ans173e" v-model="ans173" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>174. Osećam da nisam bolji/a od drugih, bez obzira na njihov položaj.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans174a" id="ans174a" v-model="ans174" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans174b" id="ans174b" v-model="ans174" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans174c" id="ans174c" v-model="ans174" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans174d" id="ans174d" v-model="ans174" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans174e" id="ans174e" v-model="ans174" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>175. Kad mi neki posao postane pretežak, sklon/a sam da započnem nešto novo.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans175a" id="ans175a" v-model="ans175" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans175b" id="ans175b" v-model="ans175" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans175c" id="ans175c" v-model="ans175" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans175d" id="ans175d" v-model="ans175" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans175e" id="ans175e" v-model="ans175" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>176. Prilično se dobro držim u kriznim situacijama.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans176a" id="ans176a" v-model="ans176" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans176b" id="ans176b" v-model="ans176" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans176c" id="ans176c" v-model="ans176" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans176d" id="ans176d" v-model="ans176" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans176e" id="ans176e" v-model="ans176" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>177. Ja sam vesela, dobro raspoložena osoba.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans177a" id="ans177a" v-model="ans177" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans177b" id="ans177b" v-model="ans177" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans177c" id="ans177c" v-model="ans177" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans177d" id="ans177d" v-model="ans177" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans177e" id="ans177e" v-model="ans177" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>178. Smatram sebe širokogrudnom osobom, tolerantnom prema životnom stilu dugih ljudi.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans178a" id="ans178a" v-model="ans178" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans178b" id="ans178b" v-model="ans178" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans178c" id="ans178c" v-model="ans178" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans178d" id="ans178d" v-model="ans178" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans178e" id="ans178e" v-model="ans178" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>179. Smatram da sva ljudska bića zaslužuju poštovanje.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans179a" id="ans179a" v-model="ans179" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans179b" id="ans179b" v-model="ans179" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans179c" id="ans179c" v-model="ans179" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans179d" id="ans179d" v-model="ans179" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans179e" id="ans179e" v-model="ans179" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>180. Retko donosim nepromišljene odluke.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans180a" id="ans180a" v-model="ans180" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans180b" id="ans180b" v-model="ans180" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans180c" id="ans180c" v-model="ans180" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans180d" id="ans180d" v-model="ans180" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans180e" id="ans180e" v-model="ans180" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>181. Imam manje strahova nego većina ljudi.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans181a" id="ans181a" v-model="ans181" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans181b" id="ans181b" v-model="ans181" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans181c" id="ans181c" v-model="ans181" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans181d" id="ans181d" v-model="ans181" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans181e" id="ans181e" v-model="ans181" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>182. Jako sam emocionalno vezan/a za svoje prijatelje.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans182a" id="ans182a" v-model="ans182" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans182b" id="ans182b" v-model="ans182" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans182c" id="ans182c" v-model="ans182" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans182d" id="ans182d" v-model="ans182" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans182e" id="ans182e" v-model="ans182" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>183. Kao dete retko kad sam uživao/la u igrama „zamisli da“.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans183a" id="ans183a" v-model="ans183" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans183b" id="ans183b" v-model="ans183" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans183c" id="ans183c" v-model="ans183" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans183d" id="ans183d" v-model="ans183" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans183e" id="ans183e" v-model="ans183" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>184. Sklon/a sam da o drugima pretpostavljam sve najbolje.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans184a" id="ans184a" v-model="ans184" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans184b" id="ans184b" v-model="ans184" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans184c" id="ans184c" v-model="ans184" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans184d" id="ans184d" v-model="ans184" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans184e" id="ans184e" v-model="ans184" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>185. Ja sam vrlo sposobna osoba.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans185a" id="ans185a" v-model="ans185" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans185b" id="ans185b" v-model="ans185" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans185c" id="ans185c" v-model="ans185" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans185d" id="ans185d" v-model="ans185" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans185e" id="ans185e" v-model="ans185" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>186. Bilo je perioda kad sam bio/la ogorčen/a i kivan/a.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans186a" id="ans186a" v-model="ans186" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans186b" id="ans186b" v-model="ans186" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans186c" id="ans186c" v-model="ans186" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans186d" id="ans186d" v-model="ans186" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans186e" id="ans186e" v-model="ans186" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>187. Društvena okupljanja su mi obično dosadna.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans187a" id="ans187a" v-model="ans187" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans187b" id="ans187b" v-model="ans187" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans187c" id="ans187c" v-model="ans187" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans187d" id="ans187d" v-model="ans187" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans187e" id="ans187e" v-model="ans187" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>188. Ponekad kada čitam poeziju ili posmatram neko umetničko delo, pretrnem od uzbuđenja.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans188a" id="ans188a" v-model="ans188" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans188b" id="ans188b" v-model="ans188" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans188c" id="ans188c" v-model="ans188" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans188d" id="ans188d" v-model="ans188" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans188e" id="ans188e" v-model="ans188" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>189. Ponekad pretim ljudima ili im laskam kako bih ih naveo/la da čine ono što želim.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans189a" id="ans189a" v-model="ans189" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans189b" id="ans189b" v-model="ans189" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans189c" id="ans189c" v-model="ans189" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans189d" id="ans189d" v-model="ans189" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans189e" id="ans189e" v-model="ans189" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>190. Nisam opsednut/a čišćenjem.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans190a" id="ans190a" v-model="ans190" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans190b" id="ans190b" v-model="ans190" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans190c" id="ans190c" v-model="ans190" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans190d" id="ans190d" v-model="ans190" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans190e" id="ans190e" v-model="ans190" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>191. Ponekad mi sve izgleda prilično tmurno i beznadežno.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans191a" id="ans191a" v-model="ans191" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans191b" id="ans191b" v-model="ans191" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans191c" id="ans191c" v-model="ans191" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans191d" id="ans191d" v-model="ans191" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans191e" id="ans191e" v-model="ans191" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>192. U razgovorima obično ja najviše govorim.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans192a" id="ans192a" v-model="ans192" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans192b" id="ans192b" v-model="ans192" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans192c" id="ans192c" v-model="ans192" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans192d" id="ans192d" v-model="ans192" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans192e" id="ans192e" v-model="ans192" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>193. Lako mi je da se uživim u ono što drugi ljudi osećaju.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans193a" id="ans193a" v-model="ans193" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans193b" id="ans193b" v-model="ans193" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans193c" id="ans193c" v-model="ans193" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans193d" id="ans193d" v-model="ans193" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans193e" id="ans193e" v-model="ans193" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>194. Sebe smatram osobom koja je sklona da čini dobra dela.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans194a" id="ans194a" v-model="ans194" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans194b" id="ans194b" v-model="ans194" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans194c" id="ans194c" v-model="ans194" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans194d" id="ans194d" v-model="ans194" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans194e" id="ans194e" v-model="ans194" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>195. Trudim se da brižljivo obavljam poslove kako ne bi trebalo da ih radim ponovo.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans195a" id="ans195a" v-model="ans195" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans195b" id="ans195b" v-model="ans195" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans195c" id="ans195c" v-model="ans195" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans195d" id="ans195d" v-model="ans195" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans195e" id="ans195e" v-model="ans195" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>196. Ako sam nekome rekao/la ili učinio/la nešto loše, teško mi je da se ponovo suočim sa tom osobom.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans196a" id="ans196a" v-model="ans196" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans196b" id="ans196b" v-model="ans196" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans196c" id="ans196c" v-model="ans196" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans196d" id="ans196d" v-model="ans196" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans196e" id="ans196e" v-model="ans196" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>197. Živim brzim životom.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans197a" id="ans197a" v-model="ans197" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans197b" id="ans197b" v-model="ans197" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans197c" id="ans197c" v-model="ans197" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans197d" id="ans197d" v-model="ans197" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans197e" id="ans197e" v-model="ans197" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>198. Praznike radije provodim u proverenom i pouzdanom mestu.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans198a" id="ans198a" v-model="ans198" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans198b" id="ans198b" v-model="ans198" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans198c" id="ans198c" v-model="ans198" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans198d" id="ans198d" v-model="ans198" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans198e" id="ans198e" v-model="ans198" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>199. Tvrdoglav/a sam i nisam sentimentalan/a.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans199a" id="ans199a" v-model="ans199" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans199b" id="ans199b" v-model="ans199" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans199c" id="ans199c" v-model="ans199" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans199d" id="ans199d" v-model="ans199" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans199e" id="ans199e" v-model="ans199" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>200. Trudim se da budem uspešan/a u svemu što radim.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans200a" id="ans200a" v-model="ans200" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans200b" id="ans200b" v-model="ans200" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans200c" id="ans200c" v-model="ans200" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans200d" id="ans200d" v-model="ans200" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans200e" id="ans200e" v-model="ans200" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>


    <div class="form-group">
      <h3>201. Ponekad impulsivno učinim nešto zbog čega se posle kajem.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans201a" id="ans201a" v-model="ans201" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans201b" id="ans201b" v-model="ans201" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans201c" id="ans201c" v-model="ans201" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans201d" id="ans201d" v-model="ans201" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans201e" id="ans201e" v-model="ans201" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>202. Privlače me jarke boje i napadni stil odevanja.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans202a" id="ans202a" v-model="ans202" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans202b" id="ans202b" v-model="ans202" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans202c" id="ans202c" v-model="ans202" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans202d" id="ans202d" v-model="ans202" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans202e" id="ans202e" v-model="ans202" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>203. Pun/a sam intelektualne znatiželje.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans203a" id="ans203a" v-model="ans203" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans203b" id="ans203b" v-model="ans203" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans203c" id="ans203c" v-model="ans203" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans203d" id="ans203d" v-model="ans203" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans203e" id="ans203e" v-model="ans203" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>204. Radije bih hvalio/la druge, nego da mene hvale drugi.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans204a" id="ans204a" v-model="ans204" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans204b" id="ans204b" v-model="ans204" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans204c" id="ans204c" v-model="ans204" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans204d" id="ans204d" v-model="ans204" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans204e" id="ans204e" v-model="ans204" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>205. Ima toliko sitnih poslova koje treba uraditi, da ih ponekad jednostavno sve zanemarim.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans205a" id="ans205a" v-model="ans205" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans205b" id="ans205b" v-model="ans205" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans205c" id="ans205c" v-model="ans205" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans205d" id="ans205d" v-model="ans205" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans205e" id="ans205e" v-model="ans205" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>206. I kad se čini da je sve pošlo naopako, ja još uvek mogu da donosim dobre odluke.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans206a" id="ans206a" v-model="ans206" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans206b" id="ans206b" v-model="ans206" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans206c" id="ans206c" v-model="ans206" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans206d" id="ans206d" v-model="ans206" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans206e" id="ans206e" v-model="ans206" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>207. Retko svoje doživljaje opisujem rečima kao što su „fantastično“ i „senzacionalno“.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans207a" id="ans207a" v-model="ans207" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans207b" id="ans207b" v-model="ans207" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans207c" id="ans207c" v-model="ans207" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans207d" id="ans207d" v-model="ans207" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans207e" id="ans207e" v-model="ans207" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>208. Smatram da ako ljudi do svoje 25. godine života ne znaju u šta da veruju, tada nešto sa njima nije u redu.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans208a" id="ans208a" v-model="ans208" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans208b" id="ans208b" v-model="ans208" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans208c" id="ans208c" v-model="ans208" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans208d" id="ans208d" v-model="ans208" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans208e" id="ans208e" v-model="ans208" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>209. Saosećam sa onima koji su manje srećni od mene.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans209a" id="ans209a" v-model="ans209" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans209b" id="ans209b" v-model="ans209" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans209c" id="ans209c" v-model="ans209" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans209d" id="ans209d" v-model="ans209" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans209e" id="ans209e" v-model="ans209" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>210. Kad idem na put, unapred sve pažljivo isplaniram.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans210a" id="ans210a" v-model="ans210" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans210b" id="ans210b" v-model="ans210" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans210c" id="ans210c" v-model="ans210" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans210d" id="ans210d" v-model="ans210" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans210e" id="ans210e" v-model="ans210" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>211. Ponekad mi padaju na pamet zastrašujuće misli.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans211a" id="ans211a" v-model="ans211" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans211b" id="ans211b" v-model="ans211" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans211c" id="ans211c" v-model="ans211" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans211d" id="ans211d" v-model="ans211" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans211e" id="ans211e" v-model="ans211" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>212. Ljudi sa kojima radim zanimaju me i privatno.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans212a" id="ans212a" v-model="ans212" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans212b" id="ans212b" v-model="ans212" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans212c" id="ans212c" v-model="ans212" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans212d" id="ans212d" v-model="ans212" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans212e" id="ans212e" v-model="ans212" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>213. Teško bih mogao/la da pustim svoje misli da lutaju bez kontrole ili cilja.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans213a" id="ans213a" v-model="ans213" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans213b" id="ans213b" v-model="ans213" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans213c" id="ans213c" v-model="ans213" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans213d" id="ans213d" v-model="ans213" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans213e" id="ans213e" v-model="ans213" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>214. Prilično verujem u ljudsku prirodu.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans214a" id="ans214a" v-model="ans214" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans214b" id="ans214b" v-model="ans214" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans214c" id="ans214c" v-model="ans214" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans214d" id="ans214d" v-model="ans214" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans214e" id="ans214e" v-model="ans214" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>215. U svom poslu sam efikasan/a.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans215a" id="ans215a" v-model="ans215" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans215b" id="ans215b" v-model="ans215" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans215c" id="ans215c" v-model="ans215" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans215d" id="ans215d" v-model="ans215" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans215e" id="ans215e" v-model="ans215" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>216. Čak i manje neprilike mogu da me frustriraju.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans216a" id="ans216a" v-model="ans216" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans216b" id="ans216b" v-model="ans216" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans216c" id="ans216c" v-model="ans216" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans216d" id="ans216d" v-model="ans216" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans216e" id="ans216e" v-model="ans216" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>217. Uživam u zabavama sa mnogo ljudi.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans217a" id="ans217a" v-model="ans217" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans217b" id="ans217b" v-model="ans217" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans217c" id="ans217c" v-model="ans217" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans217d" id="ans217d" v-model="ans217" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans217e" id="ans217e" v-model="ans217" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>218. Uživam da čitam poeziju koja naglašava osećanja i pretpostavke više nego priču.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans218a" id="ans218a" v-model="ans218" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans218b" id="ans218b" v-model="ans218" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans218c" id="ans218c" v-model="ans218" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans218d" id="ans218d" v-model="ans218" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans218e" id="ans218e" v-model="ans218" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>219. Ponosim se svojom snalažljivošću u postupanju sa ljudima.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans219a" id="ans219a" v-model="ans219" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans219b" id="ans219b" v-model="ans219" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans219c" id="ans219c" v-model="ans219" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans219d" id="ans219d" v-model="ans219" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans219e" id="ans219e" v-model="ans219" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>220. Provodim mnogo vremena tražeći stvari koje sam zaturio/la.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans220a" id="ans220a" v-model="ans220" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans220b" id="ans220b" v-model="ans220" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans220c" id="ans220c" v-model="ans220" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans220d" id="ans220d" v-model="ans220" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans220e" id="ans220e" v-model="ans220" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>221. Prečesto se obeshrabrim kad stvari krenu loše i najradije bih od svega odustao/la.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans221a" id="ans221a" v-model="ans221" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans221b" id="ans221b" v-model="ans221" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans221c" id="ans221c" v-model="ans221" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans221d" id="ans221d" v-model="ans221" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans221e" id="ans221e" v-model="ans221" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>222. Nije mi lako da preuzmem odgovornost u nekoj situaciji.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans222a" id="ans222a" v-model="ans222" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans222b" id="ans222b" v-model="ans222" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans222c" id="ans222c" v-model="ans222" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans222d" id="ans222d" v-model="ans222" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans222e" id="ans222e" v-model="ans222" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>223. Čudne stvari, poput nekih mirisa ili imena dalekih mesta, mogu u meni probuditi snažna osećanja.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans223a" id="ans223a" v-model="ans223" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans223b" id="ans223b" v-model="ans223" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans223c" id="ans223c" v-model="ans223" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans223d" id="ans223d" v-model="ans223" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans223e" id="ans223e" v-model="ans223" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>224. Ako mogu, daću sve od sebe da pomognem drugima.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans224a" id="ans224a" v-model="ans224" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans224b" id="ans224b" v-model="ans224" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans224c" id="ans224c" v-model="ans224" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans224d" id="ans224d" v-model="ans224" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans224e" id="ans224e" v-model="ans224" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>225. Zaista moram da budem bolestan/a da bih izostao/la makar i jedan dan sa posla.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans225a" id="ans225a" v-model="ans225" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans225b" id="ans225b" v-model="ans225" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans225c" id="ans225c" v-model="ans225" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans225d" id="ans225d" v-model="ans225" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans225e" id="ans225e" v-model="ans225" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>226. Kada ljudi koje poznajem urade neku glupost, meni je zbog njih nelagodno.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans226a" id="ans226a" v-model="ans226" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans226b" id="ans226b" v-model="ans226" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans226c" id="ans226c" v-model="ans226" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans226d" id="ans226d" v-model="ans226" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans226e" id="ans226e" v-model="ans226" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>227. Ja sam vrlo aktivna osoba.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans227a" id="ans227a" v-model="ans227" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans227b" id="ans227b" v-model="ans227" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans227c" id="ans227c" v-model="ans227" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans227d" id="ans227d" v-model="ans227" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans227e" id="ans227e" v-model="ans227" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>228. Kada nekuda idem, idem uvek istim putem.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans228a" id="ans228a" v-model="ans228" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans228b" id="ans228b" v-model="ans228" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans228c" id="ans228c" v-model="ans228" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans228d" id="ans228d" v-model="ans228" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans228e" id="ans228e" v-model="ans228" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>229. Često se prepirem sa članovima porodice ili saradnicima.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans229a" id="ans229a" v-model="ans229" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans229b" id="ans229b" v-model="ans229" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans229c" id="ans229c" v-model="ans229" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans229d" id="ans229d" v-model="ans229" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans229e" id="ans229e" v-model="ans229" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>230. Ja sam pomalo „radoholičar/ka“.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans230a" id="ans230a" v-model="ans230" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans230b" id="ans230b" v-model="ans230" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans230c" id="ans230c" v-model="ans230" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans230d" id="ans230d" v-model="ans230" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans230e" id="ans230e" v-model="ans230" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>231. Uvek sam u stanju da kontrolišem svoja osećanja.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans231a" id="ans231a" v-model="ans231" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans231b" id="ans231b" v-model="ans231" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans231c" id="ans231c" v-model="ans231" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans231d" id="ans231d" v-model="ans231" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans231e" id="ans231e" v-model="ans231" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>232. Sviđa mi se da budem deo mase na sportskim priredbama.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans232a" id="ans232a" v-model="ans232" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans232b" id="ans232b" v-model="ans232" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans232c" id="ans232c" v-model="ans232" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans232d" id="ans232d" v-model="ans232" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans232e" id="ans232e" v-model="ans232" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>233. Imam širok raspon intelektualnih interesovanja.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans233a" id="ans233a" v-model="ans233" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans233b" id="ans233b" v-model="ans233" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans233c" id="ans233c" v-model="ans233" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans233d" id="ans233d" v-model="ans233" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans233e" id="ans233e" v-model="ans233" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>234. Ja sam superiorna osoba.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans234a" id="ans234a" v-model="ans234" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans234b" id="ans234b" v-model="ans234" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans234c" id="ans234c" v-model="ans234" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans234d" id="ans234d" v-model="ans234" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans234e" id="ans234e" v-model="ans234" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>235. Jako sam samodisciplinovan/a.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans235a" id="ans235a" v-model="ans235" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans235b" id="ans235b" v-model="ans235" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans235c" id="ans235c" v-model="ans235" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans235d" id="ans235d" v-model="ans235" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans235e" id="ans235e" v-model="ans235" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>236. Prilično sam emocionalno stabilan/a.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans236a" id="ans236a" v-model="ans236" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans236b" id="ans236b" v-model="ans236" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans236c" id="ans236c" v-model="ans236" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans236d" id="ans236d" v-model="ans236" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans236e" id="ans236e" v-model="ans236" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>237. Lako se nasmejem.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans237a" id="ans237a" v-model="ans237" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans237b" id="ans237b" v-model="ans237" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans237c" id="ans237c" v-model="ans237" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans237d" id="ans237d" v-model="ans237" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans237e" id="ans237e" v-model="ans237" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>238. Smatram da „novi moral“ koji sve dopušta nije uopšte moral.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans238a" id="ans238a" v-model="ans238" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans238b" id="ans238b" v-model="ans238" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans238c" id="ans238c" v-model="ans238" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans238d" id="ans238d" v-model="ans238" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans238e" id="ans238e" v-model="ans238" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>239. Radije bih da budem poznat/a kao „milosrdan/a“ nego kao „pravedan/a“.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans239a" id="ans239a" v-model="ans239" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans239b" id="ans239b" v-model="ans239" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans239c" id="ans239c" v-model="ans239" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans239d" id="ans239d" v-model="ans239" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans239e" id="ans239e" v-model="ans239" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>
<div class="form-group">
      <h3>240. Dva puta razmislim pre nego što odgovorim na pitanje.
</h3>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans240a" id="ans240a" v-model="ans240" value="1">
        <label class="form-check-label" for="flexRadioDefault1">
        Uopšte se ne slažem
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans240b" id="ans240b" v-model="ans240" value="2">
        <label class="form-check-label" for="flexRadioDefault2">
          Ne slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans240c" id="ans240c" v-model="ans240" value="3">
        <label class="form-check-label" for="flexRadioDefault2">
          Neutralno
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans240d" id="ans240d" v-model="ans240" value="4">
        <label class="form-check-label" for="flexRadioDefault2">
          Slažem se
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="ans240e" id="ans240e" v-model="ans240" value="5">
        <label class="form-check-label" for="flexRadioDefault2">
          Potpuno se slažem
        </label>
      </div>
    </div>

    <div class="submitDiv">
        <button class="btn btn-primary">Submit</button>
    </div>



</form>
</div>
  </div>
  </div>

</template>

<script>
import {mapActions,mapGetters} from 'vuex';

export default {
  computed: mapGetters(['user']),
  data(){
    return{
      timerCount: 4500,
      ans1:"1",
ans2:"1",
ans3:"1",
ans4:"1",
ans5:"1",
ans6:"1",
ans7:"1",
ans8:"1",
ans9:"1",
ans10:"1",
ans11:"1",
ans12:"1",
ans13:"1",
ans14:"1",
ans15:"1",
ans16:"1",
ans17:"1",
ans18:"1",
ans19:"1",
ans20:"1",
ans21:"1",
ans22:"1",
ans23:"1",
ans24:"1",
ans25:"1",
ans26:"1",
ans27:"1",
ans28:"1",
ans29:"1",
ans30:"1",
ans31:"1",
ans32:"1",
ans33:"1",
ans34:"1",
ans35:"1",
ans36:"1",
ans37:"1",
ans38:"1",
ans39:"1",
ans40:"1",
ans41:"1",
ans42:"1",
ans43:"1",
ans44:"1",
ans45:"1",
ans46:"1",
ans47:"1",
ans48:"1",
ans49:"1",
ans50:"1",
ans51:"1",
ans52:"1",
ans53:"1",
ans54:"1",
ans55:"1",
ans56:"1",
ans57:"1",
ans58:"1",
ans59:"1",
ans60:"1",
ans61:"1",
ans62:"1",
ans63:"1",
ans64:"1",
ans65:"1",
ans66:"1",
ans67:"1",
ans68:"1",
ans69:"1",
ans70:"1",
ans71:"1",
ans72:"1",
ans73:"1",
ans74:"1",
ans75:"1",
ans76:"1",
ans77:"1",
ans78:"1",
ans79:"1",
ans80:"1",
ans81:"1",
ans82:"1",
ans83:"1",
ans84:"1",
ans85:"1",
ans86:"1",
ans87:"1",
ans88:"1",
ans89:"1",
ans90:"1",
ans91:"1",
ans92:"1",
ans93:"1",
ans94:"1",
ans95:"1",
ans96:"1",
ans97:"1",
ans98:"1",
ans99:"1",
ans100:"1",
ans101:"1",
ans102:"1",
ans103:"1",
ans104:"1",
ans105:"1",
ans106:"1",
ans107:"1",
ans108:"1",
ans109:"1",
ans110:"1",
ans111:"1",
ans112:"1",
ans113:"1",
ans114:"1",
ans115:"1",
ans116:"1",
ans117:"1",
ans118:"1",
ans119:"1",
ans120:"1",
ans121:"1",
ans122:"1",
ans123:"1",
ans124:"1",
ans125:"1",
ans126:"1",
ans127:"1",
ans128:"1",
ans129:"1",
ans130:"1",
ans131:"1",
ans132:"1",
ans133:"1",
ans134:"1",
ans135:"1",
ans136:"1",
ans137:"1",
ans138:"1",
ans139:"1",
ans140:"1",
ans141:"1",
ans142:"1",
ans143:"1",
ans144:"1",
ans145:"1",
ans146:"1",
ans147:"1",
ans148:"1",
ans149:"1",
ans150:"1",
ans151:"1",
ans152:"1",
ans153:"1",
ans154:"1",
ans155:"1",
ans156:"1",
ans157:"1",
ans158:"1",
ans159:"1",
ans160:"1",
ans161:"1",
ans162:"1",
ans163:"1",
ans164:"1",
ans165:"1",
ans166:"1",
ans167:"1",
ans168:"1",
ans169:"1",
ans170:"1",
ans171:"1",
ans172:"1",
ans173:"1",
ans174:"1",
ans175:"1",
ans176:"1",
ans177:"1",
ans178:"1",
ans179:"1",
ans180:"1",
ans181:"1",
ans182:"1",
ans183:"1",
ans184:"1",
ans185:"1",
ans186:"1",
ans187:"1",
ans188:"1",
ans189:"1",
ans190:"1",
ans191:"1",
ans192:"1",
ans193:"1",
ans194:"1",
ans195:"1",
ans196:"1",
ans197:"1",
ans198:"1",
ans199:"1",
ans200:"1",
ans201:"1",
ans202:"1",
ans203:"1",
ans204:"1",
ans205:"1",
ans206:"1",
ans207:"1",
ans208:"1",
ans209:"1",
ans210:"1",
ans211:"1",
ans212:"1",
ans213:"1",
ans214:"1",
ans215:"1",
ans216:"1",
ans217:"1",
ans218:"1",
ans219:"1",
ans220:"1",
ans221:"1",
ans222:"1",
ans223:"1",
ans224:"1",
ans225:"1",
ans226:"1",
ans227:"1",
ans228:"1",
ans229:"1",
ans230:"1",
ans231:"1",
ans232:"1",
ans233:"1",
ans234:"1",
ans235:"1",
ans236:"1",
ans237:"1",
ans238:"1",
ans239:"1",
ans240:"1",
      name:"",

    };
  },
  methods:{
    ...mapActions(['submitTestLicnosti','getProfile']),
    submitTestLicnostiThis(){
    //  console.log(this.username,this.password)


      // eslint-disable-next-line no-unused-vars
      let N1 = 0, N2 = 0, N3 = 0, N4 = 0, N5 = 0, N6 = 0;
      // eslint-disable-next-line no-unused-vars
      let E1 = 0, E2 = 0, E3 = 0, E4 = 0, E5 = 0, E6 = 0;
      // eslint-disable-next-line no-unused-vars
      let O1 = 0, O2 = 0, O3 = 0, O4 = 0, O5 = 0, O6 = 0;
      // eslint-disable-next-line no-unused-vars
      let U1 = 0, U2 = 0, U3 = 0, U4 = 0, U5 = 0, U6 = 0;
      // eslint-disable-next-line no-unused-vars
      let S1 = 0, S2 = 0, S3 = 0, S4 = 0, S5 = 0, S6 = 0;

      N1= N1
      + (5-parseInt(this.ans1))
       + (parseInt(this.ans31)-1)
       + (5-parseInt(this.ans61))
      + (parseInt(this.ans91)-1)
      + (5-parseInt(this.ans121))
      + (parseInt(this.ans151)-1)
      + (5-parseInt(this.ans181))
      + (parseInt(this.ans211)-1);
      N2= N2
      + (parseInt(this.ans6)-1)
      + (5- parseInt(this.ans36))
      + (parseInt(this.ans66)-1)
      + (5-parseInt(this.ans96))
      + (parseInt(this.ans126)-1)
      + (5-parseInt(this.ans156))
      + (parseInt(this.ans186)-1)
      + (parseInt(this.ans216)-1);
      //11r, 41, 71r, 101, 131, 161, 191, 221
      N3 = N3
      + (5- parseInt(this.ans11))
      + (parseInt(this.ans41)-1)
      + (5- parseInt(this.ans71))
      + (parseInt(this.ans101)-1)
      + (parseInt(this.ans131)-1)
      + (parseInt(this.ans161)-1)
      + (parseInt(this.ans191)-1)
      + (parseInt(this.ans221)-1);

      //16, 46r, 76, 106r, 136, 166r, 196, 226
      N4 = N4
      + (parseInt(this.ans16)-1)
      + (5- parseInt(this.ans46))
      + (parseInt(this.ans76)-1)
      + (5- parseInt(this.ans106))
      + (parseInt(this.ans136)-1)
      + (5- parseInt(this.ans166))
      + (parseInt(this.ans196)-1)
      + (parseInt(this.ans226)-1);

      //21r, 51, 81r, 111, 141r, 171, 201, 231r

      N5 = N5
      + (5- parseInt(this.ans21))
      + (parseInt(this.ans51)-1)
      + (5- parseInt(this.ans81))
      + (parseInt(this.ans111)-1)
      + (5- parseInt(this.ans141))
      + (parseInt(this.ans171)-1)
      + (parseInt(this.ans201)-1)
      + (5- parseInt(this.ans231));

      //26, 56r, 86, 116r, 146, 176r, 206r, 236r
      N6 = N6
      + (parseInt(this.ans26)-1)
      + (5- parseInt(this.ans56))
      + (parseInt(this.ans86)-1)
      + (5- parseInt(this.ans116))
      + (parseInt(this.ans146)-1)
      + (5- parseInt(this.ans176))
      + (5- parseInt(this.ans206))
      + (5- parseInt(this.ans236));
      ///////////////////////////////
      //2, 32r, 62, 92r, 122, 152, 182, 212
      E1 = E1
      + (parseInt(this.ans2)-1)
      + (5- parseInt(this.ans32))
      + (parseInt(this.ans62)-1)
      + (5- parseInt(this.ans92))
      + (parseInt(this.ans122)-1)
      + (parseInt(this.ans152)-1)
      + (parseInt(this.ans182)-1)
      + (parseInt(this.ans212)-1);

      //7r, 37, 67r, 97, 127r, 157, 187r, 217
      E2 = E2
      + (5- parseInt(this.ans7))
      + (parseInt(this.ans37)-1)
      + (5- parseInt(this.ans67))
      + (parseInt(this.ans97)-1)
      + (5- parseInt(this.ans127))
      + (parseInt(this.ans157)-1)
      + (5- parseInt(this.ans187))
      + (parseInt(this.ans217)-1);

      //12, 42r, 72, 102r, 132, 162r, 192, 222r
      E3 = E3
      + (parseInt(this.ans12)-1)
      + (5- parseInt(this.ans42))
      + (parseInt(this.ans72)-1)
      + (5- parseInt(this.ans102))
      + (parseInt(this.ans132)-1)
      + (5- parseInt(this.ans162))
      + (parseInt(this.ans192)-1)
      + (5- parseInt(this.ans222));

      //17r, 47, 77r, 107, 137r, 167, 197, 227
      E4 = E4
      + (5- parseInt(this.ans17))
      + (parseInt(this.ans47)-1)
      + (5- parseInt(this.ans77))
      + (parseInt(this.ans107)-1)
      + (5- parseInt(this.ans137))
      + (parseInt(this.ans167)-1)
      + (parseInt(this.ans197)-1)
      + (parseInt(this.ans227)-1);

      //22, 52r, 82, 112r, 142, 172, 202, 232
      E5 = E5
      + (parseInt(this.ans22)-1)
      + (5- parseInt(this.ans52))
      + (parseInt(this.ans82)-1)
      + (5- parseInt(this.ans112))
      + (parseInt(this.ans142)-1)
      + (parseInt(this.ans172)-1)
      + (parseInt(this.ans202)-1)
      + (parseInt(this.ans232)-1);

      //27r, 57, 87r, 117, 147r, 177, 207r, 237
      E6 = E6
      + (5- parseInt(this.ans27))
      + (parseInt(this.ans57)-1)
      + (5- parseInt(this.ans87))
      + (parseInt(this.ans117)-1)
      + (5- parseInt(this.ans147))
      + (parseInt(this.ans177)-1)
      + (5- parseInt(this.ans207))
      + (parseInt(this.ans237)-1);

      //3, 33r, 63, 93r, 123, 153r, 183r, 213r
      O1 = O1
      + (parseInt(this.ans3)-1)
      + (5- parseInt(this.ans33))
      + (parseInt(this.ans63)-1)
      + (5- parseInt(this.ans93))
      + (parseInt(this.ans123)-1)
      + (5- parseInt(this.ans153))
      + (5- parseInt(this.ans183))
      + (5- parseInt(this.ans213));

      //8r, 38, 68r, 98, 128r, 158, 188, 218
      O2 = O2
      + (5- parseInt(this.ans8))
      + (parseInt(this.ans38)-1)
      + (5- parseInt(this.ans68))
      + (parseInt(this.ans98)-1)
      + (5- parseInt(this.ans128))
      + (parseInt(this.ans158)-1)
      + (parseInt(this.ans188)-1)
      + (parseInt(this.ans218)-1);

      //13, 43r, 73, 103r, 133, 163r, 193, 223
      O3 = O3
      + (parseInt(this.ans13)-1)
      + (5- parseInt(this.ans43))
      + (parseInt(this.ans73)-1)
      + (5- parseInt(this.ans103))
      + (parseInt(this.ans133)-1)
      + (5- parseInt(this.ans163))
      + (parseInt(this.ans193)-1)
      + (parseInt(this.ans223)-1);

      //18r, 48, 78r, 108, 138r, 168, 198r, 228r
      O4 = O4
      + (5- parseInt(this.ans18))
      + (parseInt(this.ans48)-1)
      + (5- parseInt(this.ans78))
      + (parseInt(this.ans108)-1)
      + (5- parseInt(this.ans138))
      + (parseInt(this.ans168)-1)
      + (5- parseInt(this.ans198))
      + (5- parseInt(this.ans228));

      //23, 53r, 83, 113r, 143, 173r, 203, 233
      O5 = O5
      + (parseInt(this.ans23)-1)
      + (5- parseInt(this.ans53))
      + (parseInt(this.ans83)-1)
      + (5- parseInt(this.ans113))
      + (parseInt(this.ans143)-1)
      + (5- parseInt(this.ans173))
      + (parseInt(this.ans203)-1)
      + (parseInt(this.ans233)-1);

      //28r, 58, 88r, 118, 148r, 178, 208r, 238r
      O6 = O6
      + (5- parseInt(this.ans28))
      + (parseInt(this.ans58)-1)
      + (5- parseInt(this.ans88))
      + (parseInt(this.ans118)-1)
      + (5- parseInt(this.ans148))
      + (parseInt(this.ans178)-1)
      + (5- parseInt(this.ans208))
      + (5- parseInt(this.ans238));

      //4r, 34, 64r, 94, 124r, 154, 184, 214
      U1 = U1
      + (5- parseInt(this.ans4))
      + (parseInt(this.ans34)-1)
      + (5- parseInt(this.ans64))
      + (parseInt(this.ans94)-1)
      + (5- parseInt(this.ans124))
      + (parseInt(this.ans154)-1)
      + (parseInt(this.ans184)-1)
      + (parseInt(this.ans214)-1);

      //9, 39r, 69, 99r, 129, 159r, 189r, 219r
      U2 = U2
      + (parseInt(this.ans9)-1)
      + (5- parseInt(this.ans39))
      + (parseInt(this.ans69)-1)
      + (5- parseInt(this.ans99))
      + (parseInt(this.ans129)-1)
      + (5- parseInt(this.ans159))
      + (5- parseInt(this.ans189))
      + (5- parseInt(this.ans219));

      //14r, 44, 74r, 104, 134r, 164, 194, 224
      U3 = U3
      + (5- parseInt(this.ans14))
      + (parseInt(this.ans44)-1)
      + (5- parseInt(this.ans74))
      + (parseInt(this.ans104)-1)
      + (5- parseInt(this.ans134))
      + (parseInt(this.ans164)-1)
      + (parseInt(this.ans194)-1)
      + (parseInt(this.ans224)-1);

      //19, 49r, 79, 109r, 139, 169r, 199r, 229r
      U4 = U4
      + (parseInt(this.ans19)-1)
      + (5- parseInt(this.ans49))
      + (parseInt(this.ans79)-1)
      + (5- parseInt(this.ans109))
      + (parseInt(this.ans139)-1)
      + (5- parseInt(this.ans169))
      + (5- parseInt(this.ans199))
      + (5- parseInt(this.ans229));

      //24r, 54, 84r, 114, 144r, 174, 204, 234r
      U5 = U5
      + (5- parseInt(this.ans24))
      + (parseInt(this.ans54)-1)
      + (5- parseInt(this.ans84))
      + (parseInt(this.ans114)-1)
      + (5- parseInt(this.ans144))
      + (parseInt(this.ans174)-1)
      + (parseInt(this.ans204)-1)
      + (5- parseInt(this.ans234));

      //29, 59r, 89,119r, 149, 179, 209, 239
      U6 = U6
      + (parseInt(this.ans29)-1)
      + (5- parseInt(this.ans59))
      + (parseInt(this.ans89)-1)
      + (5- parseInt(this.ans119))
      + (parseInt(this.ans149)-1)
      + (parseInt(this.ans179)-1)
      + (parseInt(this.ans209)-1)
      + (parseInt(this.ans239)-1);

      //5, 35r, 65, 95r, 125, 155r, 185, 215
      S1 = S1
      + (parseInt(this.ans5)-1)
      + (5- parseInt(this.ans35))
      + (parseInt(this.ans65)-1)
      + (5- parseInt(this.ans95))
      + (parseInt(this.ans125)-1)
      + (5- parseInt(this.ans155))
      + (parseInt(this.ans185)-1)
      + (parseInt(this.ans215)-1);

      //10r, 40, 70r, 100, 130r, 160,190r, 220r
      S2 = S2
      + (5- parseInt(this.ans10))
      + (parseInt(this.ans40)-1)
      + (5- parseInt(this.ans70))
      + (parseInt(this.ans100)-1)
      + (5- parseInt(this.ans130))
      + (parseInt(this.ans160)-1)
      + (5- parseInt(this.ans190))
      + (5- parseInt(this.ans220));

      //15, 45r, 75, 105r, 135, 165, 195, 225
      S3 = S3
      + (parseInt(this.ans15)-1)
      + (5- parseInt(this.ans45))
      + (parseInt(this.ans75)-1)
      + (5- parseInt(this.ans105))
      + (parseInt(this.ans135)-1)
      + (parseInt(this.ans165)-1)
      + (parseInt(this.ans195)-1)
      + (parseInt(this.ans225)-1);

      //20r, 50, 80r, 110, 140r, 170, 200, 230
      S4 = S4
      + (5- parseInt(this.ans20))
      + (parseInt(this.ans50)-1)
      + (5- parseInt(this.ans80))
      + (parseInt(this.ans110)-1)
      + (5- parseInt(this.ans140))
      + (parseInt(this.ans170)-1)
      + (parseInt(this.ans200)-1)
      + (parseInt(this.ans230)-1);

      //25, 55r, 85, 115r, 145, 175r, 205r, 235
      S5 = S5
      + (parseInt(this.ans25)-1)
      + (5- parseInt(this.ans55))
      + (parseInt(this.ans85)-1)
      + (5- parseInt(this.ans115))
      + (parseInt(this.ans145)-1)
      + (5- parseInt(this.ans175))
      + (5- parseInt(this.ans205))
      + (parseInt(this.ans235)-1);

      //30r, 60, 90r, 120, 150r, 180, 210, 240
      S6 = S6
      + (5- parseInt(this.ans30))
      + (parseInt(this.ans60)-1)
      + (5- parseInt(this.ans90))
      + (parseInt(this.ans120)-1)
      + (5- parseInt(this.ans150))
      + (parseInt(this.ans180)-1)
      + (parseInt(this.ans210)-1)
      + (parseInt(this.ans240)-1);




      console.log("N1="+N1+", N2="+N2+", N3="+N3+", N4="+N4+", N5="+N5+", N6="+N6);
      console.log("E1="+E1+", E2="+E2+", E3="+E3+", E4="+E4+", E5="+E5+", E6="+E6);
      console.log("O1="+O1+", O2="+O2+", O3="+O3+", O4="+O4+", O5="+O5+", O6="+O6);
      console.log("U1="+U1+", U2="+U2+", U3="+U3+", U4="+U4+", U5="+U5+", U6="+U6);
      console.log("S1="+S1+", S2="+S2+", S3="+S3+", S4="+S4+", S5="+S5+", S6="+S6);




      // eslint-disable-next-line no-unused-vars
    let testLicnostiData = {

      N1,
      N2,
      N3,
      N4,
      N5,
      N6,

      E1,
      E2,
      E3,
      E4,
      E5,
      E6,

      O1,
      O2,
      O3,
      O4,
      O5,
      O6,

      U1,
      U2,
      U3,
      U4,
      U5,
      U6,

      S1,
      S2,
      S3,
      S4,
      S5,
      S6,
      pol:"M",
      date:Date.now(),
      finished:true,
      userId:this.user._id

    };



    this.submitTestLicnosti(testLicnostiData)
    .then(res=>{
      if(res.data.success){
        this.$router.push('/profile');
      }
    }).catch(err =>{
      console.log(err);
    });

    }
  },
  created(){
    this.getProfile();
  },
  watch: {
           timerCount: {
               handler(value) {
                   if (value > 0) {
                       setTimeout(() => {
                           this.timerCount--;
                           document.querySelector('#prog').style.width = `${this.timerCount/45}%`;
                       }, 1000);
                   }
               },
               immediate: true // This ensures the watcher is triggered upon creation
           }
       }

};
</script>

<style lang="css" scoped>
  h1{
    text-align: center;
    margin: 15px;
  }
  .submitDiv{
    width: 100%;
  }
  .btn{
    width: 100%;
    background-color: #003366;
  }
  .form-group{
    background-color: #bbffbb;
    padding: 15px;
  }
  .form-group:nth-child(2),
.form-group:nth-child(4),
.form-group:nth-child(6),
.form-group:nth-child(8),
.form-group:nth-child(10),
.form-group:nth-child(12),
.form-group:nth-child(14),
.form-group:nth-child(16),
.form-group:nth-child(18),
.form-group:nth-child(20),
.form-group:nth-child(22),
.form-group:nth-child(24),
.form-group:nth-child(26),
.form-group:nth-child(28),
.form-group:nth-child(30),
.form-group:nth-child(32),
.form-group:nth-child(34),
.form-group:nth-child(36),
.form-group:nth-child(38),
.form-group:nth-child(40),
.form-group:nth-child(42),
.form-group:nth-child(44),
.form-group:nth-child(46),
.form-group:nth-child(48),
.form-group:nth-child(50),
.form-group:nth-child(52),
.form-group:nth-child(54),
.form-group:nth-child(56),
.form-group:nth-child(58),
.form-group:nth-child(60),
.form-group:nth-child(62),
.form-group:nth-child(64),
.form-group:nth-child(66),
.form-group:nth-child(68),
.form-group:nth-child(70),
.form-group:nth-child(72),
.form-group:nth-child(74),
.form-group:nth-child(76),
.form-group:nth-child(78),
.form-group:nth-child(80),
.form-group:nth-child(82),
.form-group:nth-child(84),
.form-group:nth-child(86),
.form-group:nth-child(88),
.form-group:nth-child(90),
.form-group:nth-child(92),
.form-group:nth-child(94),
.form-group:nth-child(96),
.form-group:nth-child(98),
.form-group:nth-child(100),
.form-group:nth-child(102),
.form-group:nth-child(104),
.form-group:nth-child(106),
.form-group:nth-child(108),
.form-group:nth-child(110),
.form-group:nth-child(112),
.form-group:nth-child(114),
.form-group:nth-child(116),
.form-group:nth-child(118),
.form-group:nth-child(120),
.form-group:nth-child(122),
.form-group:nth-child(124),
.form-group:nth-child(126),
.form-group:nth-child(128),
.form-group:nth-child(130),
.form-group:nth-child(132),
.form-group:nth-child(134),
.form-group:nth-child(136),
.form-group:nth-child(138),
.form-group:nth-child(140),
.form-group:nth-child(142),
.form-group:nth-child(144),
.form-group:nth-child(146),
.form-group:nth-child(148),
.form-group:nth-child(150),
.form-group:nth-child(152),
.form-group:nth-child(154),
.form-group:nth-child(156),
.form-group:nth-child(158),
.form-group:nth-child(160),
.form-group:nth-child(162),
.form-group:nth-child(164),
.form-group:nth-child(166),
.form-group:nth-child(168),
.form-group:nth-child(170),
.form-group:nth-child(172),
.form-group:nth-child(174),
.form-group:nth-child(176),
.form-group:nth-child(178),
.form-group:nth-child(180),
.form-group:nth-child(182),
.form-group:nth-child(184),
.form-group:nth-child(186),
.form-group:nth-child(188),
.form-group:nth-child(190),
.form-group:nth-child(192),
.form-group:nth-child(194),
.form-group:nth-child(196),
.form-group:nth-child(198),
.form-group:nth-child(200),
.form-group:nth-child(202),
.form-group:nth-child(204),
.form-group:nth-child(206),
.form-group:nth-child(208),
.form-group:nth-child(210),
.form-group:nth-child(212),
.form-group:nth-child(214),
.form-group:nth-child(216),
.form-group:nth-child(218),
.form-group:nth-child(220),
.form-group:nth-child(222),
.form-group:nth-child(224),
.form-group:nth-child(226),
.form-group:nth-child(228),
.form-group:nth-child(230),
.form-group:nth-child(232),
.form-group:nth-child(234),
.form-group:nth-child(236),
.form-group:nth-child(238),
.form-group:nth-child(240)
  {
    background-color: #bbffff;
    padding: 15px;
  }

  .opis{
    background-color: #bbffff;
    padding: 15px;
    border-style: solid;
    border-color: black;
    border-width: medium;
    border-radius: 15px;
  }

</style>
